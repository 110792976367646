import React from 'react';

interface TextAreaProps {
  value: string;
  setValue: (value: string) => void;
}

export default function Textarea({ value, setValue }: TextAreaProps) {
  return (
    <textarea
      value={value}
      onChange={event => setValue(event.target.value)}
      autoFocus
      autoCapitalize="off"
      autoCorrect="off"
      spellCheck="false"
      className="h-full w-full resize-none focus:outline-none"
    />
  );
}
