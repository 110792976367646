import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import md5 from 'md5';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MarkdownProps {
  content: string;
  shouldFormat: boolean;
}

export default function Markdown({ content, shouldFormat }: MarkdownProps) {
  if (!shouldFormat)
    return <pre className="prose prose-invert w-full text-wrap">{content}</pre>;

  return (
    <ReactMarkdown
      // Since the list items aren't rendered properly after diffing, we are trying to force a re-render.
      key={md5(content)}
      className="prose prose-invert max-w-none text-gray-200"
      remarkPlugins={[remarkGfm]}
      components={{
        code(props) {
          const { children, className, node, ...rest } = props;
          const match = /language-(\w+)/.exec(className || '');
          return match ? (
            <SyntaxHighlighter
              language={match[1]}
              children={String(children)}
              style={vscDarkPlus}
            />
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
}
